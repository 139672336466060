<template>
    <div class="box">
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="banner" style="width: 1920px;position: relative;">
                <img style="width:100%;height:400px;" :src="banner.path" alt="">
                <div style="position: absolute;top:80px;left:260px;color:#ffffff;font-size:30px;">
                    <p>公司简介</p>
                    <p>————</p>
                    <p>Company Introduction</p>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="nav" style="width: 1185px;margin-top: 40px;z-index:200;">
                <div style="width:100%;display:flex;">
                    <div class="nav_l">
                        <img v-if="typeface==1" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/companyProfile/11.png" alt="">
                        <img v-if="typeface==2" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/companyProfile/11s.png" alt="">
                        <div :class="['nav_l_ul', typeface==2?'nav_l_ulEng':'']" v-for="(item, i) in typeface==1?navText:navTexts" :key="i" @click="navClick(i)">
                            <p :class="[navIndex==i?'nav_color':'']">
                                {{item}}
                                <span></span>
                            </p>
                            <img v-if="navIndex==i" src="../../../image/companyProfile/22.png" alt="">
                        </div>
                    </div>
                    <div class="nav_nav" style="display:flex;margin-top:3px;" v-if="navIndex == 0">
                        <div style="display:flex;flex-direction: column;margin-bottom:120px;">
                            <div class="nav_span">
                                <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
                                <span>></span>
                                <span>{{typeface==1?'公司简介':'Company Introduction'}}</span>
                            </div>
                            <div style="display:flex;margin-top: 20px;">
                                <div class="nav_ntp" style="width: 306px;height: 180px;border: 2px solid #C79770;margin-left:10px;margin-top:10px;">
                                    <img style="width:100%;height:100%;margin-top:-10px;margin-left:-10px;" src="../../../image/首页/jianjie.png" alt="">
                                </div>
                                <div class="nav_text" style="width:477px;margin-left: 40px;text-indent:30px;color:#7B7B7B;font-size:16px;">
                                    <span style="line-height:30px;" v-if="typeface==1">
                                        公司始建于内蒙古自治区甘其毛都口岸，在2011年成立了乌拉特中旗铖源贸易有限责任公司，是中蒙两国具备双方认证的贸易和运输企业，经过多年的市场快速发展和企业发展的需要，于2016年正式组建成立了内蒙古子拓国际贸易有限责任公司。
                公司通过煤炭全产业链、探索多元化发展，坚持“以新取胜、以信求远”的经营理念，倡导“诚实守信、优质满意、互利互赢”的企业精神，实现管理规范化、体系标准化，企业在创造经济效益的同时也创造了显著的社会效益。
                公司在煤炭贸易业务领域中集成了一套集煤炭生产、采购、运输、销售为一体的综合运营体系，在与蒙古国原煤矿区、海关监管部门的合作中签订了长期战略合作协议，为今后继续扩大中蒙两国间的煤炭贸易和运输领域奠定了坚实的基础。
                在未来发展道路上，公司将不断探索煤炭供应链资源整合，依托大数据运用和分析，形成可持续发展的规范、高效、环保的健康发展态势，成为我国内外运销行业的领导者。
                                    </span>
                                    <span style="line-height:30px;" v-if="typeface==2">The company was founded in Ganqi Maodu Port, Inner Mongolia Autonomous Region. In 2011, Ulat Zhongqi Chengyuan Trading Co., Ltd. was established. It is a trade and transportation enterprise certified by both sides in China and Mongolia. After years of rapid market development and the needs of enterprise development, Inner Mongolia Zituo International Trade Co., Ltd. was formally established in 2016.
Through the whole coal industry chain, the company explores diversified development, adheres to the business philosophy of "winning with new, seeking far with letter", advocates the enterprise spirit of "honesty and trustworthiness, quality satisfaction, mutual benefit and mutual win", realizes the standardization of management and system, and creates significant social benefits while creating economic benefits.
In the field of coal trade, the company has integrated a comprehensive operation system integrating coal production, procurement, transportation and sales, and signed long-term strategic cooperation agreements with Mongolia's raw coal mining areas and customs supervision departments, laying a solid foundation for the future expansion of coal trade and transportation between China and Mongolia.
On the road of future development, the company will continue to explore the integration of coal supply chain resources, relying on the application and analysis of big data, to form a healthy development trend of sustainable development, standard, efficient and environmental protection, and become a leader in China's internal and external transportation and marketing industry.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Development v-if="navIndex == 1"></Development>
                    <CorporatePhilosophy v-if="navIndex == 2"></CorporatePhilosophy>
                    <HonoraryAchievements v-if="navIndex == 3"></HonoraryAchievements>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

import Development from './Development';
import CorporatePhilosophy from './CorporatePhilosophy';
import HonoraryAchievements from './HonoraryAchievements';
export default {
    name: 'CompanyProfile',
    components: {
        Development,
        CorporatePhilosophy,
        HonoraryAchievements
    },
    props: ["msgIndex"],
    data() {
        return {
            navText: ['公司简介', '发展历程', '企业理念', '荣誉业绩'],
            navTexts: ['Company Introduction','Development History','Company  Vision','Awards'],
            navIndex: 0,
            banner: {
                path: ''
            },

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        msgIndex() {
            this.navIndex = this.msgIndex;
        },
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        window.scrollTo(0,0);
        this.navIndex = this.msgIndex;
        this.getData();
    },

    methods: {
        getData() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 11
                },
            }).then( res => {
                this.banner = res.data[0][0];
            });
        },
        // 左边导航 点击切换
        navClick(i) {
            this.navIndex = i;
        }
    }
}
</script>

<style scoped>
html, body {
    overflow-x: hidden;
}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
a {
    color: #656565;
}
.nav_l {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 50px;
}
.nav_l_ul {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: #292929;
    position: relative;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 8px 14px 16px 0px rgba(104, 104, 104, 0.12);
}
.nav_l_ulEng {
    justify-content: flex-start;
}
.nav_l_ul img {
    position: absolute;
    right: 20px;
    top: 20px;
}
.nav_l_ul p {
    padding: 15px 54px 15px 10px;
    font-size: 18px;
    position: relative;
}
.nav_l_ul p span {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.nav_l_ul p:hover{
    color: #8E050C;
    font-weight: 600;
}
.nav_l_ul p:hover span {
    width: 100%;
}
.nav_color {
    color: #8E050C;
    font-weight: 600;
}
.nav_tu_top {
    display: flex;
    align-items: center;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>
