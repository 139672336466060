<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },

  created() {
    
    //禁止选择网页中的文字 
    document.onselectstart = function () { return false; }
    //禁止鼠标拖动图片
    document.ondragstart = function () { return false; }
    //IE不支持
    document.oncopy = function () { return false; }

    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  }
}
</script>

<style>

</style>
