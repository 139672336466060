<template>
    <div class="nav" style="width:100%;display:flex;flex-direction: column;">
        <div class="nav_span">
            <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
            <span>></span>
            <span @click="newsFlont()">{{typeface==1?'公司新闻':'Company News'}}</span>
        </div>
        <div class="nav_nav" style="width:879px;background:#ffffff;padding:20px;border-radius:4px;">
            <p style="display:flex;justify-content: center;color:#1B1A1A;font-size:24px;font-weight:600;">{{typeface==1?name:nameEng}}</p>
            <span style="display:flex;justify-content:flex-end;color: #939393;font-size:14px;padding-top:10px;padding-bottom:5px;border-bottom:1px solid #D5D5D5;">{{typeface==1?'发布时间':'Release Time'}}：{{this.time}}</span>
            <div style="padding-top: 20px;display:flex;flex-direction: column;">
                <div style="padding-top:20px;text-indent:30px;color:#5B5B5B;font-size:16px;line-height:30px;" v-for="(item, i) in typeface==1?uls:ulsEng" :key="i">
                    {{item}}
                </div>
                <img style="margin-top:20px;" :src="this.path" alt="">
            </div>
            <p style="display:flex;justify-content:flex-end;color:#0a64b9;font-size:16px;padding-top:40px;font-weight:600;cursor: pointer;" @click="newsFlont()">{{typeface==1?'关闭当前文章':'Close Current Article'}}</p>
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

export default {
    name: 'DetailsPage',
    props: ["goIndex", "indexIds"],
    data() {
        return {
            name: '',
            nameEng: '',
            time: '',
            path: '',
            uls: [],
            ulsEng: [],
            goIndexs: false,

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        this.goIndexs = this.goIndex;
        this.getData(this.indexIds);
    },

    methods: {
        getData(id) {
            axios({
                url: url + `/content/input/${id}`,
                method: 'get'
            }).then( res => {
                console.log(res.data.data)
                let data = res.data.data;
                this.name = data.name;
                this.nameEng = data.nameEng;
                this.time = data.releaseTime;
                this.path = data.path;
                let text = data.text.split(/[\n]/);
                this.uls = text;
                let textEng = data.textEng.split(/[\n]/);
                this.ulsEng = textEng;
            });
        },
        newsFlont() {
            this.$emit('newsFlont', false , this.goIndexs);
        }
    }
}
</script>

<style scoped>
a {
    color: #656565;
}
.nav_span {
    margin-top:3px;
    margin-bottom: 20px;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a, .nav_span span:nth-child(3):hover{
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>