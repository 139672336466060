<template>
    <div class="nav" style="width:100%;display:flex;flex-direction: column;">
        <div class="nav_span">
            <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
            <span>></span>
            <span>{{typeface==1?'项目介绍':'Project Introduction'}}</span>
            <span>></span>
            <span>{{typeface==1?'境外物流':'Overseas Logistics'}}</span>
        </div>
        <div style="display:flex;margin-top: 20px;width:900px;flex-direction: column;background:#ffffff;padding: 20px;margin-bottom:40px;">
            <p style="color:#292929;font-size:16px;line-height:30px;">{{typeface==1?text:textEng}}</p>
            <img style="margin-top:20px;" :src="banner1" alt="">
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

export default {
    name: 'Development',
    data() {
        return {
            banner1: '',
            text: '',
            textEng: '',

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        this.getList();
    },

    methods: {
        getList() {
            axios({
                url: url + '/content/introduce/list?type=1',
                method: 'get'
            }).then( res => {
                let data = res.data.rows;
                this.banner1 = data[1].path;
                this.text = data[0].text;
                this.textEng = data[0].textEng;
            });
        },
    }
}
</script>

<style scoped>
a {
    color: #656565;
}
.nav_span {
    margin-top: 3px;
    margin-bottom: 0;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2), .nav_span span:nth-child(4) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>