<template>
    <div class="box">
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="banner" style="width: 1920px;position: relative;">
                <img style="width:100%;height:400px;" :src="banner.path" alt="">
                <div style="position: absolute;top:80px;left:260px;color:#ffffff;font-size:30px;">
                    <p>业务范围</p>
                    <p>————</p>
                    <p>Business</p>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="nav" style="width: 1185px;margin-top: 40px;z-index:200;">
                <div style="width:100%;display:flex;">
                    <div class="nav_l">
                        <img v-if="typeface==1" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/DomesticLogistics/44.png" alt="">
                        <img v-if="typeface==2" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/DomesticLogistics/44s.png" alt="">
                        <div :class="['nav_l_ul', typeface==2?'nav_l_ulEng':'']" v-for="(item, i) in typeface==1?navText:navTexts" :key="i" @click="navClick(i)">
                            <p :class="[navIndex==i?'nav_color':'']">
                                {{item}}
                                <span></span>
                            </p>
                            <img v-if="navIndex==i && typeface==1" src="../../../image/companyProfile/22.png" alt="">
                        </div>
                    </div>
                    <div class="nav_nav" style="display:flex;margin-top:3px;" v-if="navIndex == 0">
                        <div style="display:flex;flex-direction: column;margin-bottom:40px;">
                            <div class="nav_span">
                                <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
                                <span>></span>
                                <span>{{typeface==1?'业务范围':'Business'}}</span>
                                <span>></span>
                                <span>{{typeface==1?'甘其毛都口岸':'The Ganqimaodu Port'}}</span>
                            </div>
                            <div style="display:flex;margin-top: 20px;width:900px;flex-direction: column;background:#ffffff;padding: 20px;">
                                <p style="color:#292929;font-size:16px;line-height:30px;">{{typeface==1?text:textEng}}</p>
                                <img style="margin-top:20px;" :src="banner1" alt="">
                            </div>
                        </div>
                    </div>
                    <Mandula v-if="navIndex == 1"></Mandula>
                    <Erlianhot v-if="navIndex == 2"></Erlianhot>
                    <Business v-if="navIndex == 3"></Business>
                    <CentralFlag v-if="navIndex == 4"></CentralFlag>
                    <Dongwu v-if="navIndex == 5"></Dongwu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

import Mandula from './Mandula';
import CentralFlag from './CentralFlag';
import Erlianhot from './Erlianhot';
import Business from './Business';
import Dongwu from './Dongwu';
export default {
    name: 'CompanyProfile',
    components: {
        Mandula,
        CentralFlag,
        Erlianhot,
        Business,
        Dongwu
    },
    props: ["msgIndex"],
    data() {
        return {
            banner: {
                path: ''
            },
            navText: ['甘其毛都口岸', '满都拉口岸', '二连浩特口岸', '海煤业务', '乌拉特中旗','东乌珠穆沁旗'],
            navTexts: ['The Ganqimaodu Port', 'The Mandula Port', 'The Erenhot Port', 'Seaborne trade', 'The Urad Middle Banner','The Dongwu Banner '],
            navIndex: 0,
            banner1: '',
            text: '',
            textEng: '',

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        msgIndex() {
            this.navIndex = this.msgIndex;
        },
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        window.scrollTo(0,0);
        this.navIndex = this.msgIndex;
        this.getData();
        this.getList();
    },

    methods: {
        getData() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 14
                },
            }).then( res => {
                this.banner = res.data[0][0];
            });
        },

        getList() {
            axios({
                url: url + '/content/business/list?type=0',
                method: 'get'
            }).then( res => {
                let data = res.data.rows;
                this.banner1 = data[1].path;
                this.text = data[0].text;
                this.textEng = data[0].textEng;
            });
        },

        // 左边导航 点击切换
        navClick(i) {
            this.navIndex = i;
        }
    }
}
</script>

<style scoped>
html, body {
    overflow-x: hidden;
}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
a {
    color: #656565;
}
.nav_l {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 50px;
}
.nav_l_ul {
    display: flex;
    color: #292929;
    position: relative;
    cursor: pointer;
    justify-content: center;
    background: #ffffff;
    box-shadow: 8px 14px 16px 0px rgba(104, 104, 104, 0.12);
}
.nav_l_ulEng {
    justify-content: flex-start;
    padding-left: 10px;
}

.nav_l_ul img {
    position: absolute;
    right: 20px;
    top: 20px;
}
.nav_l_ul p {
    padding: 15px 0;
    font-size: 18px;
    position: relative;
}
.nav_l_ul p span {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.nav_l_ul p:hover{
    color: #8E050C;
    font-weight: 600;
}
.nav_l_ul p:hover span {
    width: 100%;
}
.nav_color {
    color: #8E050C;
    font-weight: 600;
}
.nav_tu_top {
    display: flex;
    align-items: center;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>
