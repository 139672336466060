<template>
    <div class="nav" style="width:100%;display:flex;flex-direction: column;">
        <div v-if="!detailsflont">
            <div class="nav_span">
                <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
                <span>></span>
                <span>{{typeface==1?'公司新闻':'Company News'}}</span>
                <span>></span>
                <span>{{typeface==1?'媒体聚焦':'Media focus'}}</span>
            </div>
            <div style="display:flex;flex-direction: column;">
                <div class="nav_tops" style="width:891px;overflow: hidden;">
                    <div class="top_lis" v-for="(item, i) in uls" :key="i" @click="detailsPages(item)">
                        <p>{{typeface==1?item.name:item.nameEng}}</p>
                        <span>{{item.releaseTime}}</span>
                    </div>
                </div>
                <div class="nav_bottom">
                    <!-- <span style="cursor: pointer;">首页</span> -->
                    <!-- <span style="margin-right:10px;cursor: pointer;" @click="lastStep()">上一页</span>
                    <span style="margin-right:10px;"><b style="color:#8E050C;">{{page}}</b>/{{inputleg}}</span>
                    <span style="cursor: pointer;" @click="nextStep()">下一页</span> -->
                    <!-- <span style="cursor: pointer;">尾页</span> -->
                    <!-- <p>跳转至</p>
                    <input style="margin:0 5px;width:20px;text-align: center;" type="text" v-model="inputText">
                    <p>页</p>
                    <span style="margin-left:40px;margin-right:0;cursor: pointer;" @click="goList()">GO</span> -->
                </div>
            </div>
        </div>
        <DetailsPages v-if="detailsflont" @newsFlont="newsFlont" :indexIds="this.indexIds"></DetailsPages>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

import DetailsPages from '../detailsPage/DetailsPages';
export default {
    name: 'Development',
    components: {
        DetailsPages
    },
    data() {
        return {
            uls: [],
            inputText: 1,
            page: 1,
            detailsflont: false,

            inputleg: 0,

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        this.getList(this.page);
    },

    methods: {
        // 获取列表 渲染页面
        getList(page) {
            axios({
                url: url + `/content/input/list?parentId=0&type=6&pageSize=10&pageNum=${page}`,
                method: 'get'
            }).then( res => {
                console.log(res.data.rows)
                this.uls = res.data.rows;
                this.inputleg = res.data.rows.length;
            });
        },

        // GO 点击事件 获取第几章页面的数据
        goList() {
            let val = this.inputText;
            if(val == "") {
                this.inputText = 1;
                val = 1;
            }
            this.page = val;
            this.getList(val);
        },

        // 跳转详情页
        detailsPages(item) {
            this.indexIds = item.id;
            this.detailsflont = true;
        },

        // 详情页给父组件传的值 隐藏详情页
        newsFlont(data) {
            this.detailsflont = data;
        },

        // 上一页
        lastStep() {
            let page = this.page * 1;
            if(page != 1) {
                this.page = page - 1;
                this.getList(page-1);
            }
        },

        // 下一页
        nextStep() {
            let page = this.page * 1;
            this.page = page + 1;
            this.getList(page+1);
        }
    }
}
</script>

<style scoped>
a {
    color: #656565;
}
.nav_span {
    margin-top: 3px;
    margin-bottom: 10px;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2), .nav_span span:nth-child(4) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
.top_lis {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    color: #3D3D3D;
    cursor: pointer;
}
.top_lis:hover {
    color: #8E050C;
    font-weight: 600;
}
.top_lis p {
    font-size: 16px;
    width: 70%;
    text-decoration: none;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.top_lis span {
    font-size: 14px;
}
.top_lis:nth-child(odd) {
    background: #ececec;
}

.nav_bottom {
    display: flex;
    width: 891px;
    justify-content: center;
    margin-top: 40px;
}
.nav_bottom span {
    padding: 4px 10px;
    border: 1px solid #C5C5C5;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 40px;
    color: #3D3D3D;
}
.nav_bottom p {
    color: #3D3D3D;
    font-size: 14px;
    padding-top: 4px;
}
</style>