<template>
    <div class="box">
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="banner" style="width: 1920px;position: relative;">
                <img style="width:100%;height:400px;" :src="banner.path" alt="">
                <div style="position: absolute;top:80px;left:260px;color:#ffffff;font-size:30px;">
                    <p>联系我们</p>
                    <p>————</p>
                    <p>Contact Us</p>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="nav" style="width: 1185px;margin-top: 40px;z-index:200;">
                <div style="width:100%;display:flex;">
                    <div class="nav_l">
                        <img v-if="typeface==1" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/za/11.png" alt="">
                        <img v-if="typeface==2" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/za/11s.png" alt="">
                        <div :class="['nav_l_ul', typeface==2?'nav_l_ulEng':'']" v-for="(item, i) in typeface==1?navText:navTexts" :key="i" @click="navClick(i)">
                            <p :class="[navIndex==i?'nav_color':'']">
                                {{item}}
                                <span></span>
                            </p>
                            <img v-if="navIndex==i" src="../../../image/companyProfile/22.png" alt="">
                        </div>
                    </div>
                    <div class="nav_nav" style="display:flex;margin-top:3px;" v-if="navIndex == 0">
                        <div style="display:flex;flex-direction: column;margin-bottom:40px;">
                            <div class="nav_span">
                                <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
                                <span>></span>
                                <span>{{typeface==1?'联系我们':'Contact Us'}}</span>
                            </div>
                            <div style="display:flex;margin-top: 20px;width:830px;background: #ffffff;flex-direction: column;padding:20px;padding-left:40px;">
                                <div class="nav_ffTop">
                                    <p style="color: #2D2D2D;font-size:18px;font-weight:600;padding-bottom:10px;">{{typeface==1?'内蒙古子拓国际贸易有限责任公司':'Zituo International Trade Co., Ltd'}}</p>
                                    <p style="color:#6B6B6B;font-size:16px;">https://www.zituobiz.com</p>
                                    <p style="color:#BB1E10;padding: 20px 0;font-size:20px;font-weight:600;">——</p>
                                    <p style="color:#6B6B6B;font-size:16px;">{{typeface==1?'内蒙古巴彦淖尔市乌拉特中旗海流图镇煤炭大厦14楼':'Floor  #14th, Coal Building, Hailiutu Town, Bayannaoer Urad Middle Banner, Inner Mongolia'}}</p>
                                    <div class="right_tu">
                                        <img src="../../../image/首页/logo.png" alt="">
                                    </div>
                                </div>
                                <div style="width:100%;display:flex;padding-top:10px;flex-wrap:wrap;padding-bottom: 20px;">
                                    <div class="item_span" v-for="(item, i) in uls" :key="i" style="display:flex;padding-top:20px;">
                                        <span style="padding: 20px 10px;border-bottom:1px solid #A3A3A3;color:#6B6B6B;font-size:16px;">{{typeface==1?item.text:item.texts}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TalentRecruitment v-if="navIndex == 3"></TalentRecruitment>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

import TalentRecruitment from './TalentRecruitment';
export default {
    name: 'CompanyProfile',
    components: {
        TalentRecruitment
    },
    props: ["msgIndex"],
    data() {
        return {
            banner: {
                path: ''
            },
            navText: ['联系我们', '发展历程', '企业理念', '人才招聘', '荣誉业绩'],
            navTexts: ['Contact Us', 'Development History', 'Business Culture', 'Talents', 'Awards'],
            navIndex: 0,
            uls: [
                {text: '中旗办事处：0478-7845777',texts: 'Inner Zhongqi Office ：0478-7845777'},
                {text: '包头办事处：0472-6221277',texts: 'Inner Baotou Office：0478-6221277'},
                {text: '国际运输业务负责人：陈会青 18247897796',texts: 'Head of International Transportation Business：Chen Huiqing 18247897796'},
                {text: '北京办事处：010-86208175',texts: 'Beijing Office：010-86208175'},
                {text: '国内运输业务负责人：董玉涛 15248887288',texts: 'Head of Domestic Transportation Business：Dong Yutao 15248887288'},
                {text: '北京销售业务负责人：张静 18811444765',texts: 'Head of Sales Business in Beijing：Zhang Jing 18811444765'},
                {text: '蒙古国乌兰巴托办事处负责人：韩海明 00976 88666512',texts: 'Head of Sales Business in Ulaanbaatar, Mongolia：Hang Haiming 00976 88666512'},
                {text: '内蒙销售业务负责人：陈磊 15248887002',texts: 'Head of Sales Business in Inner Mongolia：Chen Lei 15248887002'}
            ],

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        msgIndex() {
            this.navIndex = this.msgIndex;
        },
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        window.scrollTo(0,0);
        this.navIndex = this.msgIndex;
        this.getData();
    },

    methods: {
        getData() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 16
                },
            }).then( res => {
                this.banner = res.data[0][0];
            });
        },

        // 左边导航 点击切换
        navClick(i) {
            if(i==0 || i==3) {
                this.navIndex = i;
            } else {
                this.$emit('contactUs', i);
            }
        },
    }
}
</script>

<style scoped>
html, body {
    overflow-x: hidden;
}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
a {
    color: #656565;
}
.nav_l {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 50px;
}
.nav_l_ul {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: #292929;
    position: relative;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 8px 14px 16px 0px rgba(104, 104, 104, 0.12);
}
.nav_l_ulEng {
    justify-content: flex-start;
}
.nav_l_ul img {
    position: absolute;
    right: 20px;
    top: 20px;
}
.nav_l_ul p {
    padding: 15px 54px 15px 10px;
    font-size: 18px;
    position: relative;
}
.nav_l_ul p span {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.nav_l_ul p:hover{
    color: #8E050C;
    font-weight: 600;
}
.nav_l_ul p:hover span {
    width: 100%;
}
.nav_color {
    color: #8E050C;
    font-weight: 600;
}
.nav_tu_top {
    display: flex;
    align-items: center;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
.nav_ffTop {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.right_tu {
    position: absolute;
    right: 0;
    top: -10px;
}
.right_tu img{
    width: 120px;
    height: 120px;
}
.item_span:nth-child(odd) {
    width: 45%;
}
.item_span:nth-child(odd) span {
    width: 310px;
}
.item_span:nth-child(even) {
    width: 55%;
}
.item_span:nth-child(even) span {
    width: 420px;
}
.item_span span:hover {
    background: #f4f6f7;
    color: #373737;
    font-size: 16px;
    border-radius: 6px;
    font-weight: 600;
}
</style>
