<template>
    <div class="box">
        <div class="header">
            <div style="width:100%;display:flex;justify-content:center;">
                <div class="header_top">
                    <div style="display:flex;width:1200px;justify-content: space-between;align-items: center">
                        <div style="display:flex;padding-left:130px;">
                            <p style="padding-right:20px;">{{timeData}}</p>
                            <p>{{week}}</p>
                        </div>
                        <div style="display:flex;position: relative;padding-right:10px;">
                            <p class="header_p" style="display:flex;padding-right:40px;align-items: center;"><span style="padding-right:10px;cursor: pointer;" @click="typeface(1)">中文版</span>/<span style="padding-left:10px;cursor: pointer;" @click="typeface(2)">English version</span></p>
                            <p style="padding-right:20px;display:flex;align-items: center;">{{typefaces==1?'企业公众号':'Enterprise WeChat'}}</p>
                            <img style="cursor: pointer;width:30px;height:30px;" @mouseover="logoFlant=true" @mouseleave="logoFlant=false" src="../../image/header/微信(2).png" alt="" />
                            <transition name="fade">
                                <div class="img_img" style="position:absolute;top:40px;right:-18px;z-index:999;" v-if="logoFlant">
                                    <img style="width:80px;height:80px;" src="../../image/首页/logo.png" alt="">
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width:100%;display:flex;justify-content:center;background:#ffffff;">
                <div class="header_bottom">
                    <div :style="typefaces==1?'display:flex;':'display:flex;margin-right:200px'">
                        <img style="width: 330px; height: 62px;" src="../../image/header/logo.png" alt="">
                    </div>
                    <div class="uls">
                        <div class="lis" v-for="(item, i) in headerText" :key="i" @mouseover=navOver(i) @mouseleave=navLeave()>
                            <p :class="['text', navColor==i?'navColor':'']" @click=navclick(i)>{{typefaces==1?item.name:item.names}}</p>
                            <span class="spans"></span>
                            <div class="lis_ol" v-if="navIndex == i">
                                <span class="lis_ol_sp" v-for="(val, n) in typefaces==1?item.data:item.datas" :key="n" @click=navclicks(n)>
                                    {{val}}
                                    <span></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="section">
                <Index v-if="navColor == 0" @multipleJumps="multipleJumps" :indexGS="this.indexGS"></Index>
                <CompanyProfile v-if="navColor == 1" :msgIndex="this.navIndexs"></CompanyProfile>
                <CompanyNews v-if="navColor == 2" :msgIndex="this.navIndexs" :indexId="this.indexId" @goIndexs="goIndexs"></CompanyNews>
                <Projectintroduction v-if="navColor == 3" :msgIndex="this.navIndexs"></Projectintroduction>
                <Ganqimaodu v-if="navColor == 4" :msgIndex="this.navIndexs"></Ganqimaodu>
                <IndustryInformation v-if="navColor == 5" :msgIndex="this.navIndexs" :indexId="this.indexId" @goIndexs="goIndexs"></IndustryInformation>
                <ContactUs v-if="navColor == 6" :msgIndex="this.navIndexs" @contactUs="contactUs"></ContactUs>
                <div class="banner_left">
                    <img src="../../image/首页/dwzzzz.png" alt="">
                </div>
                <div class="banner_right">
                    <img src="../../image/首页/dwyyyy.png" alt="">
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="foot_r">
                <p>
                    <a href="https://beian.miit.gov.cn" >蒙ICP备2023002201号-1</a>
                 </p>
                <p>Copyright © 2017-2030&nbsp;
                    <a href="https://www.zituobiz.com" >内蒙古子拓国际贸易官网</a>
                    &nbsp;All Rights Reserved</p>
                <!-- <p>版权所有2021京icp备2021142442号-1</p> -->
                <!-- <div class="footer_f">
                    <span>增值电信许可证京Ab-254545464</span>
                    <span>互联网经营执照北京网监</span>
                    <span>京公网备20214545784475</span>
                </div> -->
                <!-- <p>Copyright  2015-2022</p>
                <p>内蒙古子拓国际贸易有限责任公司</p> -->
            </div>
        </div>
    </div>
</template>

<script>
import Index from './Index';
import CompanyProfile from './companyProfile/CompanyProfile';
import CompanyNews from './companyNews/CompanyNews';
import Projectintroduction from './projectintroduction/Projectintroduction';
import Ganqimaodu from './DomesticLogistics/Ganqimaodu';
import IndustryInformation from './IndustryInformation/IndustryInformation';
import ContactUs from './contactUs/ContactUs';
export default {
    name: 'HelloWorld',
    components: {
        Index,
        CompanyProfile,
        CompanyNews,
        Projectintroduction,
        Ganqimaodu,
        IndustryInformation,
        ContactUs
    },
    data() {
        return {
            timeData: '',
            week: '',
            headerText: [
                {
                    name: '首页',
                    names: 'Home',
                    data: [],
                    datas: []
                }, {
                    name: '公司简介',
                    names: 'Corporate Introduction',
                    data: ['公司简介', '发展历程', '企业理念', '荣誉业绩'],
                    datas: ['Company Introduction','Development History','Company Vision','Awards']
                }, {
                    name: '公司新闻',
                    names: 'Corporate News',
                    data: ['公司新闻', '媒体聚焦', '企业刊物'],
                    datas: ['Company News','Media focus','House Organ']
                }, {
                    name: '项目介绍',
                    names: 'Project Introduction',
                    data: ['煤炭贸易', '境外物流', '境内物流', '仓储服务'],
                    datas: ['Coal Trade','Overseas Logistics','Domestic logistics','Warehousing Services']
                }, {
                    name: '业务范围',
                    names: 'Business',
                    data: ['甘其毛都口岸', '满都拉口岸', '二连浩特口岸', '海煤业务', '乌拉特中旗','东乌珠穆沁旗'],
                    datas: ['The Ganqimaodu Port','The Mandula Port','The Erenhot Port','Seaborne trade','The Urad Middle Banner','The Dongwu Banner ']
                }, {
                    name: '行业资讯',
                    names: 'Industry News',
                    data: [],
                    datas: []
                }, {
                    name: '联系我们',
                    names: 'Contact Us',
                    data: ['联系我们', '发展历程', '企业理念', '人才招聘', '荣誉业绩'],
                    datas: ['Contact Us','Development History','Business Culture','Talents','Awards']
                }
            ],
            navIndex: null,
            navColor: 0,
            navIndexs: 0,
            logoFlant: false,

            indexId: null, // 传给公司新闻列表 展示所对应的详情页
            indexGS: 0,  // index页面 nav内容默认显示公司新闻列表

            typefaces: 1
        }
    },

    mounted() {
        this.timeDatas();
    },
    methods: {
        // 点击切换中英文
        typeface(type) {
            this.$root.TYPEFACE = type;
            this.typefaces = type;
            this.timeDatas();
        },

        timeDatas() {
            let nowDate = new Date();
            let wk = nowDate.getDay();
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            let weeksEng = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let dataEng = ['January','February','March','April','May','June','July','August','September','October','November','December'];
            let week = [];
            let date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate()
            };
            if(this.typefaces==1) {
                week = weeks[wk];
                this.timeData = date.year + '年' + date.month + '月' + date.date + '日';
            } else {
                week = weeksEng[wk];
                this.timeData = dataEng[date.month-1] + ' ' + date.date + ', ' + date.year;
            }
            this.week = week;
        },

        // 导航鼠标移入
        navOver(i) {
            this.navIndex = i;
        },

        // 导航鼠标移出
        navLeave() {
            this.navIndex = null;
        },

        // 导航点击事件 颜色变红 默认值为0
        navclick(i) {
            this.navColor = i;
            this.navIndexs = 0;
            this.indexId = null;
            this.indexGS = 0;
        },

        // 导航二级点击事件 颜色变红 默认值为0
        navclicks(n) {
            if(this.navIndex!=6 || n==0 || n==3) {
                this.navColor = this.navIndex;
                this.navIndexs = n;
            } else {
                this.contactUs(n);
            }
            this.indexId = null;
            this.indexGS = 0;
        },

        // 联系我们 点击左边导航跳转 传给父组件参数
        contactUs(data) {
            this.navColor = 1;
            if(data != 4) {
                this.navIndexs = data;
            } else {
                this.navIndexs = 3;
            }
        },

        // index页面 点击跳转传值给首页
        multipleJumps(i, n, indexId) {
            this.navColor = i;
            this.navIndexs = n;
            if(indexId) {
                this.indexId = indexId;
            }
        },

        // 首页跳转到详情页 详情页 点击关闭文章 回跳到首页
        goIndexs(id) {
            this.indexGS = id;
            this.navColor = 0;
            this.navIndexs = 0;
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
html, body {
    overflow-x: hidden;

}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
a {
    text-decoration:none;
}
.box {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f4f6f7;
}
.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 160px;
}
.header_top {
    width: 1920px;
    height: 40px;
    background: #8E050C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #de9b9f;
    font-size: 14px;
}
.header_bottom {
    width: 1200px;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_p span:hover {
    color: #f5d9db;
}
.uls {
    display: flex;
}
.lis {
    display: flex;
    align-items: center;
    height: 62px;
    padding: 0 15px;
    color: #4D4949;
    font-weight: 500;
    font-size: 18px;
    position: relative;
    text-align: center;
}

.lis .spans {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0px;
    background-color: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}

.lis:hover .spans {
    width: 80%;
}
.lis:hover p {
    color: #8E050C;
    font-weight: bold;
    cursor: pointer;
}
.text {
    position: relative;
}
.lis_ol {
    position: absolute;
    left: -10%;
    top: 100%;
    display: flex;
    flex-direction: column;
    width: 120%;
    justify-content: center;
    z-index: 999;
    background: #710304;
    opacity: 0.7;
}
.lis_ol_sp {
    color: #ffffff;
    font-size: 16px;
    padding: 12px 0;
    position: relative;
}

.lis_ol_sp span{
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0px;
    background: #ffffff;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.lis_ol_sp:hover {
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
}
.lis_ol_sp:hover span {
    width: 100%;
}
.navColor {
    color: #8E050C;
    font-weight: bold;
}

.footer{
    width: 100%;
    display: flex;
    justify-content: center;
}
.foot_r {
    width: 1920px;
    display: flex;
    flex-direction: column;
    background: #8E050C;
    padding-bottom: 15px;
}
.foot_r p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    color: #B7797C;
    font-size: 14px;
}
.footer_f {
    display: flex;
    color: #B7797C;
    font-size: 14px;
    justify-content: center;
    padding: 15px 0;
}

a{
    color: #B7797C;
}
a:hover{
    color: #f4f6f7;
    text-decoration:  underline;
}

.footer_f span {
    padding: 0 60px;
}
.footer_f span:nth-child(3) {
    padding-right: 100px;
}
.section {
    position: relative;
    padding-bottom: 40px;
    width: 1920px;
}
.banner_left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 702px;
    height: 331px;
}
.banner_left img {
    width: 100%;
    height: 100%;
}
.banner_right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 648px;
    height: 244px;
}
.banner_right img {
    width: 100%;
    height: 100%;
}


.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: opacity 1s;
}
.fade-leave-to{
    opacity: 0;
}
.fade-leave-active{
    transition: opacity 1s;
}
</style>
