<template>
    <div class="nav" style="width:100%;display:flex;flex-direction: column;">
        <div class="nav_span">
            <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
            <span>></span>
            <span>{{typeface==1?'公司简介':'Company Introduction'}}</span>
            <span>></span>
            <span>{{typeface==1?'荣誉业绩':'Awards'}}</span>
        </div>
        <img v-if="typeface==1" style="margin-bottom:40px;margin-left:-10px;" src="../../../image/companyProfile/44.png" alt="">
        <img v-if="typeface==2" style="margin-bottom:40px;margin-left:-10px;" src="../../../image/companyProfile/44s.png" alt="">
    </div>
</template>

<script>
export default {
    name: 'Development',
    data() {
        return {
            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
    }
}
</script>

<style scope>
a {
    color: #656565;
}
.nav_span {
    margin-top: 3px;
    margin-bottom: 20px;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2), .nav_span span:nth-child(4) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>