import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//样式文件一定要引入

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  data: function() {
    return {
      TYPEFACE: 1
    }
  }
}).$mount('#app')
