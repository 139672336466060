<template>
    <div class="box">
        <div style="width: 100%;display:flex;justify-content: center;">
            <div class="block" style="width: 1920px;" @click="clickBut(1,0)">
                <el-carousel :interval="2000" height="474px">
                    <el-carousel-item v-for="(item, i) in banner" :key="i">
                        <img style="width: 100%; height: 100%;" :src="item.path" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="top">
            <img style="width:160px;" src="../../image/首页/11.png" alt="">
            <p style="padding: 0 40px;font-size:28px;color:#294580;">{{typeface==1?'新闻资讯':'News Information'}}</p>
            <img style="width:160px;" src="../../image/首页/22.png" alt="">
        </div>
        <div class="nav">
            <div class="left" style="width: 482px;" @click="clickBut(2,0)">
                <el-carousel :interval="2000" height="300px">
                    <el-carousel-item v-for="(item, i) in banner1" :key="i">
                        <img style="width: 100%; height: 100%;" :src="item.path" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="right">
                <div class="top_r">
                    <div class="top_r_l">
                        <p v-for="(item, i) in typeface==1?top_text:top_texts" :key="i" :class="[top_index==i?'text_color':'']" @click="topClick(i)">
                            {{item}}
                            <span></span>
                        </p>
                    </div>
                    <div class="top_r_r" style="cursor: pointer;padding-top:5px;" @click="seeMore()">
                        <span style="padding-right:11px;">{{typeface==1?'查看更多':'See More'}}</span>
                        <span>＞</span>
                        <!-- <img style="position: absolute;top:4px;right:10px;" src="../../image/首页/矩形21拷贝.png" alt=""> -->
                    </div>
                </div>
                <div class="nav_r">
                    <div style="padding-left:5px;padding-right:3px;height:220px;" v-if="top_index==0">
                        <div @click="seeMore(tltleData)">
                            <p style="font-size:18px;color:#474747;font-weight:600;width:70%;text-decoration: none;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;line-clamp: 1; -webkit-box-orient: vertical;">{{typeface==1?tltleData.name:tltleData.nameEng}}</p>
                            <div class="titleName" style="margin-top: 10px;margin-bottom: 20px;display:flex;justify-content: space-between;">
                                <p style="font-size:16px;width:70%;text-decoration: none;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2; -webkit-box-orient: vertical;line-height:30px;">{{typeface==1?'摘要':'Abstract'}}：{{typeface==1?tltleData.text:tltleData.textEng}}</p>
                                <p style="font-size:14px;padding-top:20px;">{{tltleData.releaseTime}}</p>
                            </div>
                        </div>
                        <div class="nav_r_bottom" v-for="(item, i) in nav_bottom" :key="i" @click="seeMore(item)" v-show="i<=3">
                            <span style="width: 6px;height: 6px;background: #ffffff;border-radius: 50%;border: 2px solid #1865ab;margin-top:6px;"></span>
                            <span class="nav_bottom_te">{{typeface==1?item.name:item.nameEng}}</span>
                            <span class="nav_bottom_ti">{{item.releaseTime}}</span>
                        </div>
                    </div>
                    <div style="padding-left:5px;padding-right:3px;height:220px;" v-if="top_index==1">
                        <div class="nav_r_bottom" v-for="(item, i) in nav_bottoms" :key="i" @click="seeMore(item)" v-show="i<=6">
                            <span style="width: 6px;height: 6px;background: #ffffff;border-radius: 50%;border: 2px solid #1865ab;margin-top:6px;"></span>
                            <span class="nav_bottom_te">{{typeface==1?item.name:item.nameEng}}</span>
                            <span class="nav_bottom_ti">{{item.releaseTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav_banner" style="display:flex;width:100%;justify-content: center;margin-top:30px;flex: 1;margin-bottom: 30px;">
            <img style="margin-right:30px;cursor: pointer;width:680px;height:144px;" @click="clickBut(1,0)" :src="typeface==1?faceBanner[0]:faceBanners[0]" alt="">
            <img style="margin-right:30px;cursor: pointer;width:244px;height:144px;" @click="clickBut(1,1)" :src="typeface==1?faceBanner[1]:faceBanners[1]" alt="">
            <img style="cursor: pointer;width:244px;height:144px;" @click="clickBut(1,3)" :src="typeface==1?faceBanner[2]:faceBanners[2]" alt="">
        </div>
        <div class="top">
            <img style="width:160px;" src="../../image/首页/11.png" alt="">
            <p style="padding: 0 40px;font-size:28px;color:#294580;">{{typeface==1?'服务中心':'Service Center'}}</p>
            <img style="width:160px;" src="../../image/首页/22.png" alt="">
        </div>
        <div style="width:100%;display:flex;justify-content: center;margin-top: 40px;z-index:200;">
            <div class="bottom" style="display:flex;width:1200px;border:1px solid #E3E3E3;flex-wrap:wrap;">
                <div class="bottom_img" v-for="(item, i) in typeface==1?banner2:banner2s" :key="i" @click="clickBut(item.i,item.n)" style="width:599px;display:flex;height:180px;justify-content: center;align-items: center;" @mouseover="bannerOver(i)" @mouseout="bannerOut(i)">
                    <img style="width: 100px;height:100px;cursor: pointer;" :src="item.style==0?item.img:item.imgs" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import url from '../../js/axiosUrl';
import axios from 'axios';

export default {
    name: 'index',
    props: ["indexGS"],
    data() {
        return {
            banner: [],
            banner1: [],
            banner2: [
                {
                    img: require('../../image/index/l1.png'),
                    imgs: require('../../image/index/l1.1.png'),
                    i: 3,
                    n: 0,
                    style: 0
                }, {
                    img: require('../../image/index/l2.png'),
                    imgs: require('../../image/index/l2.2.png'),
                    i: 4,
                    n: 0,
                    style: 0
                }, {
                    img: require('../../image/index/l3.png'),
                    imgs: require('../../image/index/l3.3.png'),
                    i: 6,
                    n: 3,
                    style: 0
                }, {
                    img: require('../../image/index/l4.png'),
                    imgs: require('../../image/index/l4.4.png'),
                    i: 6,
                    n: 0,
                    style: 0
                }
            ],
            banner2s: [
                {
                    img: require('../../image/index/l1s.png'),
                    imgs: require('../../image/index/l1ss.png'),
                    i: 3,
                    n: 0,
                    style: 0
                }, {
                    img: require('../../image/index/l2s.png'),
                    imgs: require('../../image/index/l2.2s.png'),
                    i: 4,
                    n: 0,
                    style: 0
                }, {
                    img: require('../../image/index/l3s.png'),
                    imgs: require('../../image/index/l3.3s.png'),
                    i: 6,
                    n: 3,
                    style: 0
                }, {
                    img: require('../../image/index/l4s.png'),
                    imgs: require('../../image/index/l4.4s.png'),
                    i: 6,
                    n: 0,
                    style: 0
                }
            ],
            top_text: ['公司新闻', '行业资讯'],
            top_texts: ['Corporate News', 'Business'],
            top_index: 0,
            nav_bottom: [],
            nav_bottoms: [],

            tltleData: {},
            tltleDatas: {},
            faceBanner: [require('../../image/首页/走进子拓.png'),require('../../image/首页/发展历程.png'),require('../../image/首页/123.png')],
            faceBanners: [require('../../image/首页/走进子拓s.png'),require('../../image/首页/发展历程s.png'),require('../../image/首页/123s.png')],

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        aaa(type) {
            this.typeface = type;
            this.getData(type);
        }
    },
    

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        window.scrollTo(0,0);
        this.top_index = this.indexGS; 
        this.getData(this.typeface);
        this.getList();     // 公司新闻列表
        this.getLists();    // 行业资讯列表
        this.getTitle();    // 摘要
    },

    methods: {
        getTitle() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 8
                },
            }).then( res => {
                console.log(res.data[0][0])
                this.tltleData = res.data[0][0];
            });
        },

        //公司新闻列表
        getList() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 5
                },
            }).then( res => {
                this.nav_bottom = res.data[0];
            });
        },

        // 行业资讯列表
        getLists() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 7
                },
            }).then( res => {
                this.nav_bottoms = res.data[0];
            });
        },
        getData(type) {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 0,
                    pathEng: type-1
                },
            }).then( res => {
                console.log(res)
                this.banner = res.data[0]
            });
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 1
                },
            }).then( res => {
                this.banner1 = res.data[0]
            });
        },
        // 右边布局 点击切换事件
        topClick(i) {
            this.top_index = i;
        },

        // 点击更多 列表
        seeMore(item) {
            let indexId = undefined;
            if(item != undefined) {
                indexId = item.id
            }
            let id = this.top_index;
            if(id == 0) {
                this.clickBut(2,0,indexId);
            } else {
                this.clickBut(5,0,indexId);
            }
        },

        // 多种跳转
        clickBut(i, n, indexId) {
            console.log(i,n,indexId)
            this.$emit('multipleJumps', i, n, indexId);
        },

        // 鼠标滑入事件
        bannerOver(id) {
            let data = this.banner2;
            let datas = this.banner2s;
            data[id].style = 1;
            datas[id].style = 1;
            this.banner2 = data;
            this.banner2s = datas;
        },
        // 鼠标滑出事件
        bannerOut(id) {
            let data = this.banner2;
            let datas = this.banner2s;
            data[id].style = 0;
            datas[id].style = 0;
            this.banner2 = data;
            this.banner2s = datas;
        }
    }
}
</script>

<style scoped>
html, body {
    overflow-x: hidden;
}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
.top {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.top img {
    width: 350px;
    height: 27px;
}
.nav {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}
.right {
    width: 700px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
}
.top_r {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 10px;
}
.top_r_l {
    display: flex;
    color: #414141;
}
.top_r_l p {
    position: relative;
    font-size: 20px;
    margin-right: 40px;
    padding: 0 5px;
}
.top_r_l p span {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: -10px;
    background: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.top_r_l p:hover{
    color: #8E050C;
    font-weight: 600;
    cursor: pointer;
}
.top_r_l p:hover span {
    width: 100%;
}
.top_r_r span {
    color: #999999;
    font-size: 14px;
}
.text_color {
    color: #8E050C;
    font-weight: 600;
}

.nav_r {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
}
.nav_r_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 18px;
}
.nav_r_bottom:hover .nav_bottom_te {
    color: #8E050C;
    font-weight: 600;
}
.nav_r_bottom:hover .nav_bottom_ti {
    color: #8E050C;
    font-weight: 600;
}

.titleName p {
    color: #6C6C6C;
}
.titleName:hover p {
    color: #8E050C;
    font-weight: 600;
}
.nav_bottom_te {
    color: #323232;
    font-size: 16px;
    width: 83%;
    text-decoration: none;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.nav_bottom_ti {
    color: #6C6C6C;
    font-size:14px;
}
.bottom_img {
    background: #ffffff;
}
.bottom_img:nth-child(odd) {
    border-right: 1px solid #E3E3E3;
}
.bottom_img:nth-child(-n+2) {
    border-bottom: 1px solid #E3E3E3;
}

.bottom_img:hover {
    background: #8893B0;
}

.bottom {
    box-shadow: 0px 4px 20px 0px rgba(147, 155, 164, 0.25);
    margin-bottom: 40px;
}

</style>