<template>
    <div class="box">
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="banner" style="width: 1920px;position: relative;">
                <img style="width:100%;height:400px;" :src="banner.path" alt="">
                <div style="position: absolute;top:80px;left:260px;color:#ffffff;font-size:30px;">
                    <p>项目介绍</p>
                    <p>————</p>
                    <p>Project Introduction</p>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="nav" style="width: 1185px;margin-top: 40px;z-index:200;">
                <div style="width:100%;display:flex;">
                    <div class="nav_l">
                        <img v-if="typeface==1" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/projectintroduction/55.png" alt="">
                        <img v-if="typeface==2" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/projectintroduction/55s.png" alt="">
                        <div :class="['nav_l_ul', typeface==2?'nav_l_ulEng':'']" v-for="(item, i) in typeface==1?navText:navTexts" :key="i" @click="navClick(i)">
                            <p :class="[navIndex==i?'nav_color':'']">
                                {{item}}
                                <span></span>
                            </p>
                            <img v-if="navIndex==i" src="../../../image/companyProfile/22.png" alt="">
                        </div>
                    </div>
                    <div class="nav_nav" style="display:flex;margin-top:3px;" v-if="navIndex == 0">
                        <div style="display:flex;flex-direction: column;margin-bottom:40px;">
                            <div class="nav_span">
                                <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
                                <span>></span>
                                <span>{{typeface==1?'项目介绍':'Project Introduction'}}</span>
                                <span>></span>
                                <span>{{typeface==1?'煤炭贸易':'Coal Trade'}}</span>
                            </div>
                            <div style="display:flex;margin-top: 20px;width:900px;flex-direction: column;background:#ffffff;padding: 20px;">
                                <p style="color:#292929;font-size:16px;line-height:30px;">{{typeface==1?text:textEng}}</p>
                                <img style="margin-top:20px;" :src="banner1" alt="">
                            </div>
                        </div>
                    </div>
                    <OverseasLogistics v-if="navIndex == 1"></OverseasLogistics>
                    <DomesticLogistics v-if="navIndex == 2"></DomesticLogistics>
                    <WarehousingServices v-if="navIndex == 3"></WarehousingServices>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

import OverseasLogistics from './OverseasLogistics';
import DomesticLogistics from './DomesticLogistics';
import WarehousingServices from './WarehousingServices';
export default {
    name: 'CompanyProfile',
    components: {
        OverseasLogistics,
        DomesticLogistics,
        WarehousingServices
    },
    props: ["msgIndex"],
    data() {
        return {
            banner: {
                path: ''
            },
            navText: ['煤炭贸易', '境外物流', '境内物流', '仓储服务'],
            navTexts: ['Coal Trade', 'Overseas Logistics', 'Domestic logistics', 'Warehousing Services'],
            navIndex: 0,
            banner1: '',
            text: '',
            textEng: '',

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        msgIndex() {
            this.navIndex = this.msgIndex;
        },
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        window.scrollTo(0,0);
        this.navIndex = this.msgIndex;
        this.getData();
        this.getList();
    },

    methods: {
        getData() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 13
                },
            }).then( res => {
                this.banner = res.data[0][0];
            });
        },

        getList() {
            axios({
                url: url + '/content/introduce/list?type=0',
                method: 'get'
            }).then( res => {
                let data = res.data.rows;
                this.banner1 = data[1].path;
                this.text = data[0].text;
                this.textEng = data[0].textEng;
            });
        },

        // 左边导航 点击切换
        navClick(i) {
            this.navIndex = i;
        }
    }
}
</script>

<style scoped>
html, body {
    overflow-x: hidden; 
}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
a {
    color: #656565;
}
.nav_l {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 50px;
}
.nav_l_ul {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: #292929;
    position: relative;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 8px 14px 16px 0px rgba(104, 104, 104, 0.12);
}
.nav_l_ulEng {
    justify-content: flex-start;
}
.nav_l_ul img {
    position: absolute;
    right: 20px;
    top: 20px;
}
.nav_l_ul p {
    padding: 15px 54px 15px 10px;
    font-size: 18px;
    position: relative;
}
.nav_l_ul p span {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.nav_l_ul p:hover{
    color: #8E050C;
    font-weight: 600;
}
.nav_l_ul p:hover span {
    width: 100%;
}
.nav_color {
    color: #8E050C;
    font-weight: 600;
}
.nav_tu_top {
    display: flex;
    align-items: center;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>