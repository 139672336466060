<template>
    <div class="box">
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="banner" style="width: 1920px;position: relative;">
                <img style="width:100%;height:400px;" :src="banner.path" alt="">
                <div style="position: absolute;top:80px;left:260px;color:#ffffff;font-size:30px;">
                    <p>公司新闻</p>
                    <p>————</p>
                    <p>Company News</p>
                </div>
            </div>
        </div>
        <div style="width: 100%;display: flex;justify-content: center;">
            <div class="nav" style="width: 1185px;margin-top: 40px;z-index:200;">
                <div style="width:100%;display:flex;margin-bottom:40px;">
                    <div class="nav_l">
                        <img v-if="typeface==1" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/companyNews/11.png" alt="">
                        <img v-if="typeface==2" style="width:215px;height:34px;margin-right: 50px;margin-left:-15px;" src="../../../image/companyNews/11s.png" alt="">
                        <div :class="['nav_l_ul', typeface==2?'nav_l_ulEng':'']" v-for="(item, i) in typeface==1?navText:navTexts" :key="i" @click="navClick(i)">
                            <p :class="[navIndex==i?'nav_color':'']">
                                {{item}}
                                <span></span>
                            </p>
                            <img v-if="navIndex==i" src="../../../image/companyProfile/22.png" alt="">
                        </div>
                    </div>
                    <div class="nav_nav" style="display:flex;margin-top:3px;" v-if="navIndex==0&&!detailsflont"> 
                        <div style="display:flex;flex-direction: column;">
                            <div class="nav_span">
                                <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
                                <span>></span>
                                <span>{{typeface==1?'公司新闻':'Company News'}}</span>
                            </div>
                            <div style="display:flex;margin-top: 10px;flex-direction: column;">
                                <div class="nav_tops" style="width:891px;overflow: hidden;">
                                    <div class="top_lis" v-for="(item, i) in uls" :key="i" @click="detailsPages(item)">
                                        <p>{{typeface==1?item.name:item.nameEng}}</p>
                                        <span>{{item.releaseTime}}</span>
                                    </div>
                                </div>
                                <div class="nav_bottom">
                                    <!-- <span style="cursor: pointer;">首页</span> -->
                                    <!-- <span style="margin-right:10px;cursor: pointer;" @click="lastStep()">上一页</span>
                                    <span style="margin-right:10px;"><b style="color:#8E050C;">{{page}}</b>/{{inputleg}}</span>
                                    <span style="cursor: pointer;" @click="nextStep()">下一页</span> -->
                                    <!-- <span style="cursor: pointer;">尾页</span> -->
                                    <!-- <p>跳转至</p>
                                    <input style="margin:0 5px;width:20px;text-align: center;" type="text" v-model="inputText">
                                    <p>页</p>
                                    <span style="margin-left:40px;margin-right:0;cursor: pointer;" @click="goList()">GO</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <DetailsPage v-if="detailsflont" @newsFlont="newsFlont" :goIndex="this.goIndex" :indexIds="this.indexIds"></DetailsPage>
                    <MediaFocus v-if="navIndex == 1"></MediaFocus>
                    <HouseOrgan v-if="navIndex == 2"></HouseOrgan>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import url from '../../../js/axiosUrl';
import axios from 'axios';

import DetailsPage from '../detailsPage/DetailsPage';
import MediaFocus from './MediaFocus';
import HouseOrgan from './HouseOrgan';
export default {
    name: 'CompanyNews',
    components: {
        DetailsPage,
        MediaFocus,
        HouseOrgan
    },
    props: ["msgIndex","indexId"],
    data() {
        return {
            banner: {
                path: ''
            },
            navText: ['公司新闻', '媒体聚焦', '企业刊物'],
            navTexts: ['Company News', 'Media focus', 'House Organ'],
            navIndex: 0,
            uls: [],
            inputText: 1,
            page: 1,
            detailsflont: false,
            indexIds: null,  // 详情页 id

            goIndex: false,  // 是否返回首页

            inputleg: 0,   // 列表多少条

            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        msgIndex() {
            this.detailsflont = false;
            this.navIndex = this.msgIndex;
        },
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
        window.scrollTo(0,0);
        this.navIndex = this.msgIndex;
        if(this.indexId) {
            this.detailsflont = true;
            this.indexIds = this.indexId;
            this.goIndex = true;
        }
        this.getList(this.page);
        this.getData();
    },

    methods: {
        getData() {
            axios({
                url: url + '/content/input/fileList',
                method: 'post',
                data: {
                    parentId: 0,
                    type: 12
                },
            }).then( res => {
                this.banner = res.data[0][0];
            });
        },
        
        // 获取列表 渲染页面
        getList(page) {
            axios({
                url: url + `/content/input/list?parentId=0&type=5&pageSize=10&pageNum=${page}`,
                method: 'get'
            }).then( res => {
                this.uls = res.data.rows;
                this.inputleg = res.data.rows.length;
            });
        },

        // GO 点击事件 获取第几章页面的数据
        goList() {
            let val = this.inputText;
            if(val == "") {
                this.inputText = 1;
                val = 1;
            }
            this.page = val;
            this.getList(val);
        },

        // 左边导航 点击切换
        navClick(i) {
            this.navIndex = i;
            this.detailsflont = false;
        },

        // 跳转详情页
        detailsPages(item) {
            this.indexIds = item.id;
            this.detailsflont = true;
            this.goIndex = false;
        },

        // 详情页给父组件传的值 隐藏详情页
        newsFlont(data, goIndexs) {
            this.detailsflont = data;
            if(goIndexs) {
                this.$emit("goIndexs", 0);
            }
        },

        // 上一页
        lastStep() {
            let page = this.page * 1;
            if(page != 1) {
                this.page = page - 1;
                this.getList(page-1);
            }
        },

        // 下一页
        nextStep() {
            let page = this.page * 1;
            this.page = page + 1;
            this.getList(page+1);
        }
    }
}
</script>

<style scoped>
html, body {
    overflow-x: hidden; 
}
html, body, div, p, span, img {
    margin: 0;
    padding: 0;
}
a {
    color: #656565;
}
.nav_l {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 50px;
}
.nav_l_ul {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    color: #292929;
    position: relative;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 8px 14px 16px 0px rgba(104, 104, 104, 0.12);
}
.nav_l_ulEng {
    justify-content: flex-start;
}
.nav_l_ul img {
    position: absolute;
    right: 20px;
    top: 20px;
}
.nav_l_ul p {
    padding: 15px 54px 15px 10px;
    font-size: 18px;
    position: relative;
}
.nav_l_ul p span {
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background: #8E050C;
    -webkit-transition: width 0.5s;
    transition: width 0.5s;
    left: 50%;
    transform: translate(-50%, 0);
}
.nav_l_ul p:hover{
    color: #8E050C;
    font-weight: 600;
}
.nav_l_ul p:hover span {
    width: 100%;
}
.nav_color {
    color: #8E050C;
    font-weight: 600;
}
.nav_tu_top {
    display: flex;
    align-items: center;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}

.top_lis {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    color: #3D3D3D;
    cursor: pointer;
}
.top_lis:hover {
    color: #8E050C;
    font-weight: 600;
}
.top_lis p {
    font-size: 16px;
    width: 70%;
    text-decoration: none;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
.top_lis span {
    font-size: 14px;
}
.top_lis:nth-child(odd) {
    background: #ececec;
}

.nav_bottom {
    display: flex;
    width: 891px;
    justify-content: center;
    margin-top: 40px;
}
.nav_bottom span {
    padding: 4px 10px;
    border: 1px solid #C5C5C5;
    font-size: 14px;
    border-radius: 4px;
    margin-right: 40px;
    color: #3D3D3D;
}
.nav_bottom p {
    color: #3D3D3D;
    font-size: 14px;
    padding-top: 4px;
}
</style>