<template>
    <div class="nav" style="width:100%;display:flex;flex-direction: column;">
        <div class="nav_span">
            <span><a href="/">{{typeface==1?'首页':'Home Page'}}</a></span>
            <span>></span>
            <span>{{typeface==1?'公司简介':'Company Introduction'}}</span>
            <span>></span>
            <span>{{typeface==1?'企业理念':'Company Vision'}}</span>
        </div>
        <div class="nav_nav" style="width:900px;margin-bottom:40px;">
            <div class="nav_tops" v-if="typeface==1" style="display:flex;flex-direction: column;padding-top:10px;">
                <div v-for="(item, i) in lis_top" :key="i" style="display:flex;padding-bottom:40px;">
                    <p style="color:#000000;font-weight:600;padding-right:10px;">{{item.text}}:</p>
                    <span style="color:#7B7B7B;">{{item.texts}}</span>
                </div>
            </div>
            <div class="nav_tops" v-if="typeface==2" style="display:flex;flex-direction: column;padding-top:10px;">
                <div v-for="(item, i) in lis_bottom" :key="i" style="display:flex;padding-bottom:40px;">
                    <p style="color:#000000;font-weight:600;padding-right:10px;">{{item.text}}:</p>
                    <span style="color:#7B7B7B;">{{item.texts}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CorporatePhilosophy',
    data() {
        return {
            lis_top: [
                {
                    text: '企业精神',
                    texts: '永不放弃、永不止步。'
                },
                {
                    text: '廉洁理念',
                    texts: '清白为企，慎权守法。'
                },
                {
                    text: '人才理念',
                    texts: '以人为本，唯才是用。'
                },
                {
                    text: '企业愿景',
                    texts: '成为中国煤炭运销行业的领跑者。'
                },
                {
                    text: '企业使命',
                    texts: '发展企业、贡献国家、服务社会、造福员工。'
                }
            ],
            lis_bottom: [
                {
                    text: 'Company Spirit',
                    texts: 'Never give up and never stop.'
                },
                {
                    text: 'Integrity Concept',
                    texts: 'Be an innocent enterprise, be careful of power and abide by the law.'
                },
                {
                    text: 'Talent concept',
                    texts: 'People oriented and appreciate talents.'
                },
                {
                    text: 'Corporate Vision',
                    texts: "Become a leader in China's coal transportation and marketing industry."
                },
                {
                    text: 'Corporate Mission',
                    texts: "Develop enterprises, contribute to the country, serve the society and benefit employees."
                }
            ],
            typeface: 1
        }
    },

    computed: {
      aaa() {
        return this.$root.TYPEFACE;
      }
    },

    watch: {
        aaa(type) {
            this.typeface = type;
        }
    },

    mounted() {
        this.typeface = this.$root.TYPEFACE;
    }
}
</script>

<style scope>
a {
    color: #656565;
}
.nav_span {
    margin-top: 3px;
    margin-bottom: 20px;
}
.nav_span span {
    color: #656565;
    font-size: 16px;
    padding-right: 10px;
}
.nav_span span:nth-child(2), .nav_span span:nth-child(4) {
    font-size: 20px;
    color: #BB1E10;
}
.nav_span span:nth-child(1):hover a {
    color: #8E050C;
    cursor: pointer;
    font-weight: 600;
}
</style>